
import api from '@/config/api';
import {IFieldProperty, IPagination, IProperties, IResponse} from '@/config/apiTypes';
import {LocationQuery, RouteRecordName} from "vue-router";
import {routeFilters} from "@/routes";
import Slider from '@vueform/slider';
import MultipleSelect from '@/components/input/MultipleSelect.vue';
import MultipleCheck from '@/components/input/MultipleCheck.vue';
import SearchSelect from '@/components/input/SearchSelect.vue';
import Pagination from '@/components/Pagination.vue';
import ShareModal from '@/components/ShareModal.vue';
import {defineComponent, ref} from 'vue';
import {formatCashNumber, getUrl} from '@/utils/functions';
import '@vueform/slider/themes/default.css';
import Footer from '@/components/Footer.vue';
import GoToTopButton from '@/components/GoToTopButton.vue';

const area_privativa: number = 135;
const area_terreno: number = 133;
const area_total: number = 134;

interface fieldsShowType {
  name: string;
  icon: string;
  code: number;
  slug?: string;
}

export interface IFilters<T> {
  data: T[];
  loading: boolean;
}

interface filtersInputType {
  tipo: Array<string>;
  cidade: Array<string>;
  bairro: Array<string>;
  codigo: Array<number>;
  referencia: string;
  dormitorios: Array<number>;
  lancamento: "";
  banheiros: Array<number>;
  suites: Array<number>;
  vagas: Array<number>;
  valor: Array<number>;
  metragem: Array<number>;
  ordem: string;
  empreendimento: Array<string>;
  tipoEmpreendimento: Array<string>;
  page: number;
  isPromotion: number
}

declare interface dataType {
  fieldsToShow: fieldsShowType[];
  filters: any;
  activeCheckboxId: string | null;
  tipos: IFilters<{ Tipo: string }>;
  bairros: IFilters<{ Bairro: string }>;
  cidades: IFilters<{ Cidade: string }>;
  empreendimentos: IFilters<{ Empreendimento: string }>;
  tipoEmpreendimentos: IFilters<{ TipoEmpreendimento: string }>;
  filterInput: filtersInputType;
  imoveis: {
    data: IProperties[];
    error: string | null;
    loading: boolean;
    pagination: IPagination;
  };
  shareModal: {
    visible: boolean;
    link: string;
  };
  tableBairros: Array<string>;
}

interface filterParamsType {
  [key: string]: string;
}

const filterParams: filterParamsType = {
  codigo: "array",
  referencia: "string",
  dormitorios: "array",
  banheiros: "array",
  suites: "array",
  vagas: "array",
  cidade: "array",
  bairro: "array",
  valor: "array",
  metragem: "array",
  lancamento: "string",
  tipo: "array",
  empreendimento: 'array',
  tipoEmpreendimento: 'array',
  ordem: "string",
  page: "string",
  isPromotion: "number"
}

export default defineComponent({
  beforeMount() {
    this.getQueriesToInputs();
    this.getProperties();
  },
  components: {
    Pagination,
    MultipleSelect,
    MultipleCheck,
    SearchSelect,
    ShareModal,
    Slider,
    'app-footer': Footer,
    GoToTopButton,
  },
  setup() {
    const mapRef = ref(null);
    return {mapRef, formatCashNumber}
  },
  data(): dataType {
    return {
      fieldsToShow: [
        {name: "Quartos", icon: "bed", code: 153},
        {name: "Banheiros", icon: "bath", code: 136},
        {name: "Área", icon: "arrows-alt", code: area_terreno, slug: "m²"}, //Área do terreno
        {name: "Área", icon: "arrows-alt", code: area_total, slug: "m²"}, //Área total
        {name: "Área", icon: "arrows-alt", code: area_privativa, slug: "m²"} //Área privativa
      ],
      filters: routeFilters,
      activeCheckboxId: null,
      tipos: {
        data: [],
        loading: false
      },
      cidades: {
        data: [],
        loading: false
      },
      bairros: {
        data: [],
        loading: false
      },
      empreendimentos: {
        data: [],
        loading: false
      },
      tipoEmpreendimentos: {
        data: [],
        loading: false
      },
      filterInput: {
        tipo: [],
        cidade: [],
        bairro: [],
        codigo: [],
        referencia: "",
        dormitorios: [],
        banheiros: [],
        suites: [],
        vagas: [],
        valor: [0, 11000000],
        lancamento: "",
        metragem: [0, 600],
        empreendimento: [],
        tipoEmpreendimento: [],
        ordem: "",
        page: 1,
        isPromotion: 1
      },
      imoveis: {
        data: [],
        error: null,
        loading: false,
        pagination: null
      },
      shareModal: {
        visible: false,
        link: ""
      },
      tableBairros: [],
    };
  },
  methods: {
    getUrl,
    isActive(name: RouteRecordName, params?: LocationQuery): string {
      try {
        if (params && this.$router.currentRoute.value.name.toString() == name) {
          return JSON.stringify(this.$router.currentRoute.value.query) == JSON.stringify(params) ? 'active' : null;
        }
        return this.$router.currentRoute.value.name.toString() == name ? 'active' : null;
      } catch (error) {
        return null;
      }
    },
    formatValue(value: number) {
        if (value >= 3000000) {
            return '+ R$3.000.000';
        }
      return value.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
          });
    },
    formatFootage(value: number) {
      return `M² ${Math.round(value)}`
    },
    getQueriesToInputs() {
      this.filterInput = {
        ...this.filterInput,
        ...this.$route.query
      }
    },
    getValue(data: Array<number>) {
      this.filterInput.valor = data;
    },
    getFootage(data: Array<number>) {
      this.filterInput.metragem = data;
    },
    getPropertyReference(data: string) {
      this.filterInput.referencia = data;
    },
    showCheckboxes(checkboxDivId: string): void {
      this.activeCheckboxId = this.activeCheckboxId == checkboxDivId ? null : checkboxDivId;
      window.onclick = (e: MouseEvent) => {
        var target = e.target as HTMLElement;
        if (!target.closest(".multiple-select"))
          this.activeCheckboxId = null;
      };
    },
    getEnterprises(search?: string) {
      if (this.empreendimentos.data.length == 0 || search) {
        this.empreendimentos.loading = true;
        const params = search ? {pesquisa: search} : null
        api.get('/enterprises', {params})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.empreendimentos.data = data;
              }
            })
            .finally(() => {
              this.empreendimentos.loading = false;
            })
      }
    },
    getTypeEnterprises(search?: string) {
      if (this.tipoEmpreendimentos.data.length == 0 || search) {
        this.tipoEmpreendimentos.loading = true;
        const params = search ? {pesquisa: search} : null
        api.get('/typeenterprises', {params})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.tipoEmpreendimentos.data = data;
              }
            })
            .finally(() => {
              this.tipoEmpreendimentos.loading = false;
            })
      }
    },
    getTypes() {
      if (this.tipos.data.length == 0) {
        this.tipos.loading = true;
        api.get(`/types?promotion=1`)
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.tipos.data = data;
              }
            })
            .finally(() => {
              this.tipos.loading = false;
            })
      }
    },
    getCities() {
      if (this.cidades.data.length == 0) {
        this.cidades.loading = true;
        api.get(`/cities/tops?promotion=1`, {params: {seaSide: 'Não'}})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.cidades.data = data;
              }
            })
            .finally(() => {
              this.cidades.loading = false;
            })
      }
    },
    getDistricts() {
      if (this.bairros.data.length == 0) {
        this.bairros.loading = true;
        api.get(`/districtsSite?promotion=1`, {params: {seaSide: 'Não', todos: 'Sim'}})
            .then(res => {
              const data = res.data.data;
              if (data) {
                this.bairros.data = data;
              }
            })
            .finally(() => {
              this.bairros.loading = false;
            })
      }
    },
    getFilterParamsFromQueryAndInput(page?: number) {
      try {
        const rawParams = {...this.$route.query, ...this.filterInput, page};
        const paramsKV = Object.entries(rawParams).map(([key, value]) => {
          if (value != null && value != "" && Object.keys(filterParams).includes(key)) {
            const newValue = (filterParams[key] == "array" && typeof value != "object") ? [value] : value;
            return [key, newValue];
          }
        }).filter(Boolean);
        const params = Object.fromEntries(paramsKV.values());

        this.$router.replace({query: params});
        this.filterInput = {...this.filterInput, ...params};

        return params;
      } catch (error) {
        console.error("Houve um erro ao carregar filtros: ", error);
        return {};
      }
    },
    getProperties(page?: number) {
      this.imoveis.loading = true;
      const params = this.getFilterParamsFromQueryAndInput(page);
      api.get<IResponse<IProperties[]>>('/properties', {params})
          .then(res => {
            const data = res.data.data;
            if (data) {
              this.imoveis.error = "";
              this.imoveis.pagination = res.data.pagination;
              return this.imoveis.data = data;
            }
            this.imoveis.error = res.data.message;
          })
          .catch(() => {
            this.imoveis.error = "Não foi possível carregar os imóveis";
          })
          .finally(() => {
            this.imoveis.loading = false;
          })
    },
    changePage(page: number) {
      const query = {...this.$route.query, page};
      this.$router.replace({query});

      this.getProperties(page);
    },
    openShareModal(link: string) {
      this.shareModal = {
        link,
        visible: true
      }
    },
    getBairroPai(bairro: string) {
      //Obtendo bairro pai, caso houver
      this.tableBairros.forEach((value: any) => {
        if (value.bairro == bairro && value.id_bairro_pai) {
          this.tableBairros.forEach((val: any) => {
            if (val.id == value.id_bairro_pai)
              bairro = val.bairro; //Substitui pelo bairro pai
          });
        }
      });
      return bairro;
    },
    getAvaiableFields(fields: IFieldProperty[]) {
      const AreaFieldArray = [area_terreno, area_total, area_privativa];
      let AreaField: number;

      //Definindo prioridade de qual campo de Área deve ser mostrado
      if (fields.find(v => v.pivot.CodigoCampo == area_privativa))
        AreaField = area_privativa;
      else if (fields.find(v => v.pivot.CodigoCampo == area_total))
        AreaField = area_total;
      else if (fields.find(v => v.pivot.CodigoCampo == area_terreno))
        AreaField = area_terreno;
      return this.fieldsToShow.map(show => {
        const field = fields.find(v => v.pivot.CodigoCampo == show.code);
        if (field && AreaFieldArray.includes(show.code) && AreaField == show.code) {
          return {...show, valor: show?.slug ? field.pivot.Valor + show.slug : field.pivot.Valor}
        } else if (field && !AreaFieldArray.includes(show.code)) {
          return {...show, valor: show?.slug ? field.pivot.Valor + show.slug : field.pivot.Valor}
        }
      }).filter(Boolean);
    },
    capitalize(text: string): string {
      try {
        const textLowed = text.toLowerCase();
        return textLowed.split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      } catch (error) {
        return text;
      }
    },
    generateSlug(title: string): string {
      return title.normalize('NFC').replaceAll(/\W{1,}/g, '-').toLowerCase();
    },
  },
  computed: {
    location: () => {
      return window.location.href;
    }
  },

})
